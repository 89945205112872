import React from 'react'
import {Form} from "../../plugins/gatsby-plugin-atollon";
import Select from "react-select";
import {SmallRightArrow} from "./icons";

const FormComponent = (props) => {

  const staticValues = {}

  if (props.title) {
    staticValues['property'] = props.title
  }

  return (
    <Form
      schema={props.form}
      values={staticValues}
      renderField={(field) => {
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          return (
            <div className='form__text'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={field.label === 'Property' ? 'hidden' : field.type}
                defaultValue={field.label === 'Property' ? props.property : undefined}
                id={field.id}
                autoComplete={field.autoComplete}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "select") {
          let options = []
          field.options.map((option) => (
            options.push({ value: option.value, label: option.label })
          ))
          const handleTypeSelect = (e) => {
            let event = {
              target: {
                name: field.name,
                value: e.value
              }
            }
            field.onChange(event)
          };
          return (
            <>
              <div className={`form__select form__select--${field.name}`}>
                <label htmlFor={field.id}>
                  {field.label}
                </label>
                <Select
                  classNamePrefix='react-select'
                  className='react-select'
                  ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={handleTypeSelect}
                  id={field.id}
                  name={field.name}
                  aria-required={field.required}
                  disabled={field.disabled}
                  aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                  aria-invalid={field?.invalid}
                  options={options}
                  placeholder={field.placeholder}
                />
                {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
                )}
              </div>
            </>
          )
        }

        if (field.type === "radio") {
          return (
            <div className='form__radio'>
              <div className='form__radio-label'>
                {field.label}
              </div>
              {field.options.map((option) => (
                <div>
                  <input
                    id={option.id}
                    type="radio"
                    value={option.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkboxes") {
          return (
            <div className='form__checkboxes'>
              <label className='form__checkboxes-label'>
                {field.label}
              </label>
              {field.options.map((option) => (
                <div className="form__checkbox">
                  <input
                    type="checkbox"
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    id={option.id}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                    value={option.value}
                  />
                  <label htmlFor={option.id}>
                    {option.label}
                  </label>
                </div>
              ))}
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === 'file') {
          return (
            <div className={`form__file form__file--${field.label.toLowerCase().split(' ').join('-')}`}>
              <label className='form__file-label'>
                {field.label}
              </label>
              <div className='form__dropzone dropzone form__file-dropzone'>
                { field.files ?
                  <div className='dropzone__content'>
                    {field.files.map((file) => (
                      <p key={file.name}>{file.name}</p>
                    ))}
                  </div>
                  :
                  <div className='dropzone__content'>
                    {field.hasErrors ? (
                      <>
                        <p><strong>There was an issue uploading your file.</strong></p>
                        <div role="alert">{field.errors.join(", ")}</div>
                      </>
                    ) : (
                      <>
                        <h2><span>Tap to </span>{field.label}</h2>
                        <h3><span>Drag & Drop</span></h3>
                        <p>Or browse to choose a file</p>
                        <p>{field.options?.map(el => <span>{el.label}</span>)} up to 5mb</p>
                      </>
                    )}
                  </div>
                }
                <div className='dropzone__button'>
                  <div className='btn'>
                    <input
                      ref={field.ref}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onClick={field.onClick}
                      accept={field.accept}
                      multiple={field.multiple}
                      onDrop={field.onDrop}
                      name={field.name}
                      type={field.type}
                      id={field.id}
                      placeholder={field.placeholder}
                      aria-required={field.required}
                      disabled={field.disabled}
                      aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                      aria-invalid={field?.invalid}
                    />
                    {field.isUploadingFile && "Uploading..."}
                    {field.fileUploadSuccessful && "Uploaded"}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div>{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => ( <div className='form__submit'><button className='btn btn--black' type="submit">{children} <SmallRightArrow /></button></div>) }
      renderLoadingSpinner={() => <div>Loading</div>}
    />
  )
}

export default FormComponent