import React from "react";
import {Link} from "../../plugins/gatsby-plugin-atollon";
import {Logo, LeftChevron, RightChevron} from "./icons";

const Header = ({pageContext}) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [offCanvasResidential, setOffCanvasResidential] = React.useState(false)
  const [offCanvasCommercial, setOffCanvasCommercial] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [scrolledFold, setScrolledFold] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [direction, setDirection] = React.useState('down')

  let props = {
    onClick: () => {
      setOffCanvas(false)
    },
    activeClassName: 'active',
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    // if scrolled past 100vh fold
    hhScrolled = curScroll > 1
    if (hhScrolled) {
      setScrolledFold(true)
    } else {
      setScrolledFold(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let headerClass = "header"
  if (scrolled || offCanvas) headerClass += " header--scrolled"
  if (scrolledFold) headerClass += " header--fold"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if ( direction === 'down' && scrolled && !offCanvas ) headerClass += ' header--up'

  return (
    <>
      <header className={headerClass}>
        <div className='header__inner'>
          <button className={`header__hamburger${offCanvas ? ' active' : ''}`} onClick={() => setOffCanvas(!offCanvas)}>
            <span className='lines' />
          </button>
          <div className='header__nav header__nav--left'>
            <ul>
              <li>
                <Link to='/for-sale/' {...props}>Residential</Link>
                <ul>
                  <li><Link to='/for-sale/' {...props}>For Sale</Link></li>
                  <li><Link to='/sold/' {...props}>Sold</Link></li>
                  <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                  <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                  <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                  <li><Link to='/team/' {...props}>Find an Agent</Link></li>
                </ul>
              </li>
              <li>
                <Link to='/commercial-for-sale/' {...props}>Commercial</Link>
                <ul>
                  <li><Link to='/commercial-for-sale/' {...props}>For Sale</Link></li>
                  <li><Link to='/commercial-sold/' {...props}>Sold</Link></li>
                  <li><Link to='/commercial-lease/' {...props}>For Lease</Link></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className='header__logo'>
            <Link {...props} to='/'><Logo/></Link>
          </div>
          <div className='header__nav header__nav--right'>
            <ul>
              <li>
                <Link to='/about/' {...props}>About</Link>
                <ul>
                  <li><Link to='/team/' {...props}>Team</Link></li>
                  <li><Link to='/about/' {...props}>Our Story</Link></li>
                </ul>
              </li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </div>
          <span className='header__hamburger' />
        </div>
      </header>
      <div className={`off-canvas ${offCanvas ? 'active' : ''}`}>
        <div className='off-canvas__inner'>
          <div className='off-canvas__nav'>
            <ul>
              <li>
                <button onClick={() => setOffCanvasResidential(true)}>Residential <RightChevron /></button>
                <div className={`off-canvas__sub${offCanvasResidential ? ' active' : ''}`}>
                  <div>
                    <button onClick={() => setOffCanvasResidential(false)}><LeftChevron /></button>
                    <h4>Residential</h4>
                  </div>
                  <ul>
                    <li><Link to='/for-sale/' {...props}>For Sale</Link></li>
                    <li><Link to='/sold/' {...props}>Sold</Link></li>
                    <li><Link to='/appraisals/' {...props}>Appraisals</Link></li>
                    <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                    <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                    <li><Link to='/team/' {...props}>Find an Agent</Link></li>
                  </ul>
                </div>
              </li>
              <li>
                <button onClick={() => setOffCanvasCommercial(true)}>Commercial <RightChevron /></button>
                <div className={`off-canvas__sub${offCanvasCommercial ? ' active' : ''}`}>
                  <div>
                    <button onClick={() => setOffCanvasCommercial(false)}><LeftChevron /></button>
                    <h4>Commercial</h4>
                  </div>
                  <ul>
                    <li><Link to='/commercial-for-sale/' {...props}>For Sale</Link></li>
                    <li><Link to='/commercial-sold/' {...props}>Sold</Link></li>
                    <li><Link to='/commercial-lease/' {...props}>For Lease</Link></li>
                  </ul>
                </div>
              </li>
              <li><Link to='/about/' {...props}>About</Link></li>
              <li><Link to='/team/' {...props}>Team</Link></li>
              <li><Link to='/contact/' {...props}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header