import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
      query {
        ...GlobalData
        subscribeForm: datoCmsForm(title: {eq: "Subscribe"}) {
          ...FormProps
        }
        optionsPage: datoCmsSiteOptionPage {
          errorImage {
            ...InternalAssetProps
          }
        }
      }
  `)
}

export default useLayoutQuery