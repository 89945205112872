import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()

    return (
      <AtollonProvider {...props} globalData={globalData}>
        <Header pageContext={props.pageContext} />
        <main>
          {props.children}
        </main>
        <Footer/>
      </AtollonProvider>
    )
}

export default Layout